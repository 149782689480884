import './App.scss';
import Main from "./Components/Main";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function App() {



    return (
        <Container className='content-container' fluid>
            <Row className="justify-content-md-center" >
                <Col md={12} sm={12} className='main-col'  >
                    <Main/>
                </Col>
            </Row>
        </Container>
    );
}

export default App;


import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "./information.css";
import trans from "../../helpers";


const TicketInformation = (props) => {

    return (
        <Container>
            <Row className="card-container">

                <Col className="justify-content-md-center ">
                    <Card className="dataCard">
                        <Card.Body>
                            <div className="wrapper">

                                <div className="inner-top">
                                    <p className="duration-title">
                                        {trans('payment_details_amount', 'Total amount')}

                                    </p>
                                </div>
                                <div className="inner-bottom">
                                    <strong className="amount-text"> {props.ticketInformation.data.ticket.amount} </strong>
                                    {props.ticketInformation.data.ticket.promotion_name  &&
                                    <h2>
                                        <p className="amount-promotion"><span>{props.ticketInformation.data.ticket.promotion_name}</span> {trans('promotion_is_applied', 'is applied')}</p>
                                    </h2>
                                    }
                                 </div>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col className="justify-content-md-center">
                    <Card className="dataCard">

                        <Card.Body>

                            <div className="wrapper">
                                <div className="inner-top">
                                    <p className="duration-title" >
                                        {trans('parking_duration_title', 'Duration')}

                                    </p>
                                </div>
                                <div className="inner-bottom">
                                    <p className="duration-text"><strong> {props.ticketInformation.data.ticket.duration} </strong></p>

                                </div>
                            </div>


                        </Card.Body>
                    </Card>
                </Col>

            </Row>


            <Row className="card-container">

                <Col className="justify-content-md-center">
                    <Card className="dataCard">

                        <Card.Body>

                            <div className="wrapper">
                                <div className="inner-top">
                                    <p className="entry-date-title">
                                        {trans('entry_time_title', 'Entry time')}

                                    </p>
                                </div>
                                <div className="inner-bottom">
                                    <p className="entry-date-text"><strong>{props.ticketInformation.data.ticket.entry_datetime}</strong></p>

                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>

                <Col className="justify-content-md-center">
                    <Card className="dataCard">

                        <Card.Body>
                            <div className="wrapper">
                                <div className="inner-top">
                                    <p className="ticket-number-title">
                                        {trans('ticket_number_title', 'Ticket number')}
                                    </p>
                                </div>
                                <div className="inner-bottom">
                                    <p className="ticket-number-text"><strong>{props.ticketInformation.data.ticket_value}</strong></p>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>


    )
}
export default TicketInformation;
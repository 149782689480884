import './Main.css';
import Barcode from "./Barcode/Barcode";
import Information from "./Information";
import React from "react";
import SuccessPage from "./SuccessPage/SuccessPage";
import Redirect from "./SuccessRedirect/redirect";


export default class Main extends React.Component {

    // state = {
    //     data: {
    //         phone: "",
    //         reference: "1657558904445"
    //
    //     },
    //     currentStep: 'success',
    //     dataBag: {}
    // }

    state = {
        data: {
            phone: "",
            reference: ""

        },
        currentStep: 'information',
        dataBag: {}
    }


    updateStep = data => {
        this.setState({
            currentStep: data.step,
            data: data.data
        })
    }


    render() {
        // get user input type
        const params = new URLSearchParams(window.location.search);


        if (params.has('reference') && params.has('reference') ) {
            localStorage.removeItem("epan");
            localStorage.removeItem("reference");


            return (
                <Redirect updateStep={this.updateStep} />
            )
        }else {

            if (document.querySelector('meta[name="input_type"]').content === 'lpn') {
                return (
                    <Information updateStep={this.updateStep} />
                )
            } else {
                return (
                    <Barcode updateStep={this.updateStep} />
                )
            }
        }


 }

}


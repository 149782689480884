import React, {useState} from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './Barcode.scss'
import trans from "../../helpers";
import {Form} from "react-bootstrap";
import {Formik} from 'formik'
import API from "../Backend/API";
import Quagga from "quagga";
import Webcam from "react-webcam";
import {Button} from "react-bootstrap";

const Barcode = props => {

    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(false)
    const [imageSrc, setImageSrc] = useState('');


    const getBarcodeFromImage = (data) => {
        return new Promise(barcode => {

            Quagga.decodeSingle({
                decoder: {
                    readers: ["code_128_reader"] // List of active readers
                },
                locate: true, // try to locate the barcode in the image
                src: data

            }, function (result) {
                if (result !== undefined && result.codeResult) {
                    barcode(result.codeResult.code);
                } else {
                    barcode(null);
                 }
            }, function (error) {
                setImageSrc('')
                setError(trans('barcode_invalid_image', 'INVALID IMAGE'));
            });
        });
    }


    const _onDetected = async image64 => {
        const ticket_type = 'barcode'
        const ticket_value = await getBarcodeFromImage(image64).catch(() => {
            setImageSrc('')
            setError(trans('barcode_invalid_barcode_image', 'Invalid barcode image'));
            hideSpinner();
        })

        if (ticket_value === null) {
            setImageSrc('')
            setError(trans('barcode_invalid_barcode_image', 'Invalid barcode image'));
            hideSpinner();
            return;
        }

        API.post(`ticket`, {ticket_type, ticket_value, qr: localStorage.getItem('parking')})
            .then(res => {

                if (!res.data.success) {
                    setErrorMessage({
                        server_error: res.data.message
                    })
                } else {
                    props.updateStep({
                        data: {
                            ticket: res.data.content,
                            ticket_type,
                            ticket_value,
                            phone: props.bag.phone
                        }
                    })
                }
                hideSpinner();
            }).catch(error => {
            setError(trans('barcode_backend_down', 'Communication error with our backend services'));
            setImageSrc('')
            hideSpinner();

        })
    }


    // Capture image
    // user / environment
    const videoConstraints = {
        facingMode: "environment"
    };
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        () => {
            setImageSrc('')
            document
                .getElementById("spinner-1")
                .classList.add("spinner-loading");
            document.getElementById("spinner-1").disabled = true;
            const imageSrc = webcamRef.current.getScreenshot();
            setImageSrc(imageSrc)
            _onDetected(imageSrc)
        },
        [webcamRef]
    );


    // upload image
    // return base64
    const onFileChange = (e) => {
        try {
            document
                .getElementById("spinner-2")
                .classList.add("spinner-loading");
            document.getElementById("spinner-2").disabled = true;
            let files = e.target.files;
            let fileReader = new FileReader();
            if (!files[0].type.match('image.*')) {
                setError(trans('barcode_invalid_barcode_image', 'Invalid barcode image'));
            }
            fileReader.readAsDataURL(files[0]);
            fileReader.onload = (event) => {
                _onDetected(event.target.result)
            }
        } catch (e) {
            hideSpinner();
            setError(trans('barcode_invalid_barcode_image', 'Invalid barcode image'));
        }
    }

    const uploadImage = () => {
        window.document.getElementById('uploadImage').click();
    }


    // hide spinner

    const hideSpinner = () => {
        document
            .getElementById("spinner-1")
            .classList.remove("spinner-loading");
        document.getElementById("spinner-1").disabled = false;
        document
            .getElementById("spinner-2")
            .classList.remove("spinner-loading");
        document.getElementById("spinner-2").disabled = false;
    }

    return (
        <Formik className='main-container'
                initialValues={{}}
        >
            {({
                  handleSubmit,
                  handleChange,
              }) => (
                <Form noValidate onSubmit={handleSubmit} onChange={handleChange}>
                    <Row>
                        <Col md={12} sm={12}>
                            <div className="barcodeHeader">
                                    <p           className='barcode-title'>{trans('scan_barcode', 'SCAN BARCODE')}</p>
                                    <p
                                    className='barcode-sub-title'>{trans('scan_barcode', 'Take a photo for the parking ticket with the phone camera')}</p>
                            </div>

                            <div className="barcodeBody" id="video-stream">
                                 { imageSrc === '' ? <Webcam
                                    audio={false}
                                    screenshotFormat="image/jpeg"
                                    forceScreenshotSourceSize="true"
                                    videoConstraints={videoConstraints}
                                    ref={webcamRef}
                                /> : <img width={100} height={100} className="captureImg" alt="QuickPay" src={imageSrc}/> }
                             </div>


                            <Button id="spinner-1" onClick={capture} variant="primary" size="lg" block className='mt-3'
                                    type='button'>{trans('barcode_capture_photo', 'Capture photo')}</Button>

                            <Button id="spinner-2" onClick={uploadImage} variant="primary" size="lg" block
                                    className='mt-3'
                                    type='button'>{trans('barcode_upload_photo', 'upload photo')}</Button>

                            <p style={{'color': 'red'}}> {errorMessage.server_error} </p>
                            <p style={{'color': 'red'}}> {error} </p>
                            <input type="file" className="form-control" name="image" onChange={onFileChange}
                                   id='uploadImage'></input>


                        </Col>

                    </Row>
                </Form>
            )}
        </Formik>
    )
}


export default Barcode

import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Information.css';
import API from "./Backend/API";
import TicketInformation from "./TicketInfo/TicketInformation";
import trans from "../helpers";
import Row from "react-bootstrap/Row";
import PaymentOptions from "./../PaymentOptions/PaymentOptions";
import {Modal, Button, Alert} from "react-bootstrap";
import ReactCountryFlag from "react-country-flag"


export default class Information extends React.Component {


    constructor(props) {
        super(props)

        this.state = {
            paymentSpinner: undefined,
            showSpinner: false,
            successData: null,
            lpn: false,
            lpnError: false,
            lpnErrorMessage: '',
            showModal: false,
            error: false,
            errorMessage: '',
            parking: {
                name: null,
                address: null,
                image: null,
                parking_express_image: null
            },
            reference: '',
            ticketInformation: {
                data: {
                    ticket: {
                        amount: ''
                    },
                    phone: null,
                    ticket_type: "epan",
                    ticket_value: '',
                },
            }
        }


    }

    handleClose() {
        this.setState({
            lpn: false,
            showModal: false
        })
    };


    // get LPN DATA
    retrieveLpnData(e) {
        const x = document.getElementById("lpn").value;
        this.setState({
            lpnError: false,
            showSpinner: true
        })
        const ticket_type = 'license_plate'
        const ticket_value = Object.values(x).join('')
        var elements = document.getElementsByClassName("dataCard");
        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.add('is-loading')
        }

        API.post(`ticket`, {ticket_type, ticket_value, qr: localStorage.getItem('parking')})
            .then(res => {
                if (!res.data.success) {
                    this.setState({
                        showSpinner: false,
                        lpnError: true,
                        lpnErrorMessage: res.data.message
                    })
                } else {
                    this.setState({
                        showSpinner: false,
                        lpn: true,
                        showModal: false,
                        lpnError: false,
                        ticketInformation: {
                            data: {ticket: res.data.content, ticket_type, ticket_value}
                        }
                    })
                }
                for (var i = 0; i < elements.length; i++) {
                    elements[i].classList.remove('is-loading')
                }
            }).catch(error => {
            this.setState({
                showSpinner: false,
                lpnError: true,
                lpnErrorMessage: 'Backend is down'
            })
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove('is-loading')
            }
        })
    }

    //
    lpnChanged(event) {
        this.setState({lpn: event.target.value});

    }

    getLangName(language) {

        switch (language) {
            case'en':
                return {"label": "English", "value": 'en', "flag": 'GB', "is_default": 1};
            case'it':
                return {"label": "Italiano", "value": 'it', "flag": 'IT', "is_default": 1};
            case'fr':
                return {"label": "Français", "value": 'fr', "flag": 'FR', "is_default": 1};
            case'ar':
                return {"label": "عربي", "value": 'ar', "flag": 'BH', "is_default": 1};
            case'el':
                return {"label": "Greek", "value": 'el', "flag": 'GR', "is_default": 1};
            default:
                return {"label": "English", "value": 'en', "flag": 'GD', "is_default": 1};
        }
    }


    getSelectedLanguage() {


        const language = localStorage.getItem("language") ? localStorage.getItem("language") : 'en';

        if (language === 'en') {
            const lang = document.querySelector('meta[name="language"]').content;

            return this.getLangName(lang);

        } else {
            return this.getLangName('en');

        }

    }


    handleLanguageChange(locale) {



        if (locale === 'en') {
            localStorage.setItem("language", 'en');
            window.location.reload();
            return;
        } else {
            const language = document.querySelector('meta[name="language"]').content;
            localStorage.setItem("language", language);
            window.location.reload();
            return;
        }


    }

    componentDidMount() {

        const locale = localStorage.getItem("language") ? localStorage.getItem("language") : "en";
        localStorage.removeItem('reference')
        localStorage.removeItem('callback-payment')
        // update language
        if (locale === 'ar') {
            document.getElementById('body').style.direction = "rtl";
            let root = document.documentElement;
            root.style.setProperty('--main-font-family', "'Segoe UI Semilight'");
            root.style.setProperty("--main-font-weight", 900);
        }
        // fetch translations
        API.get(`translations`)
            .then((res) => {
                localStorage.setItem(
                    "translations",
                    JSON.stringify(res.data.translations)
                );
            })
            .catch((error) => {
             //   console.log(error)
            });


        let epan = new URLSearchParams(new URL(window.location.href).search).get(
            "epan"
        );
        if (epan) {
            localStorage.setItem("epan", epan);
            var elements = document.getElementsByClassName("dataCard");
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.add('is-loading')
            }


            API.get(`epan/${epan}`)
                .then((res) => {

                    if (!res.data.success ) {
                        this.setState({
                            errorMessage: res.data.message
                        })
                    }

                    this.setState({
                        parking: {...res.data.content.parking},
                        ticketInformation: {
                            data: {
                                ticket: {...res.data.content},
                                phone: null,
                                ticket_type: "epan",
                                ticket_value: epan,
                            },
                        }
                    });


                    localStorage.setItem("parking", res.data.content.parking_qr);
                    localStorage.setItem("epan", epan);

                    for (var i = 0; i < elements.length; i++) {
                        elements[i].classList.remove('is-loading')
                    }
                })

                .catch((error) => {
                    this.setState({
                        error: true,
                    })

                });
        } else {
            this.setState({
                lpn: true,
                showModal: true
            })
            // get parking data
            let parking = new URLSearchParams(
                new URL(window.location.href).search
            ).get("parking_qr");
            if (parking) {
                localStorage.setItem("parking", parking);
            } else {
                parking = localStorage.getItem("parking");
            }
            API.get(`parking/${parking}`)
                .then((res) => {
                    this.setState({
                        parking: {...res.data.parking}
                    });
                })
                .catch((error) => {
                    // console.log(error)
                    // alert(JSON.stringify(error, null, 2))
                });
        }

    }


    componentDidUpdate() {


        var listener = setInterval(() => {
            if (['success', 'error', 'close'].includes(localStorage.getItem('callback-payment'))) {

                let ticket_type = localStorage.getItem('ticket_type');
                let ticket_value = localStorage.getItem('ticket_value');

                localStorage.removeItem('callback-payment')
                let reference = localStorage.getItem('reference')
                localStorage.removeItem('reference')

                 API.post(`payment/validate`, {
                    reference: reference,
                    qr: localStorage.getItem('parking'),
                    ticket_type: ticket_type,
                    ticket_value
                })
                    .then(res => {
                        // document.querySelector("#spinner-3").classList.remove("spinner-loading")
                        // document.querySelector("#spinner-3").disabled = false
                        if (res.data.success) {
                            // If the payment is verified, stop the listener and go to success page
                            //  let data = {data: {reference, phone: ''}, step: 'success'};
                            localStorage.removeItem('reference', reference);
                            this.setState({
                                reference: reference,
                                paymentSpinner: false
                            })
                            localStorage.setItem('reference', reference);

                            clearInterval(listener)

                        } else {

                            this.setState({
                                error: res.data.message,
                                errorMessage: res.data.message,
                                paymentSpinner: false,
                             })

                        }
                    }).catch(error => {
                    this.setState({
                        error: trans('payment_verified_error', 'Could not verify your payment, please try again.'),
                        errorMessage: trans('payment_verified_error', 'Could not verify your payment, please try again.'),
                        showSpinner: false
                    })

                    // document.querySelector("#spinner-3").classList.remove("spinner-loading")
                    // document.querySelector("#spinner-3").disabled = false
                })
            }
        }, 1000);
    }


    render() {
        return (

            <div>
                <div className="main-container">
                    <div>
                        <Button
                            className="list language-btn"
                            onClick={() => this.handleLanguageChange(this.getSelectedLanguage().value)}
                            value={this.getSelectedLanguage().value}>
                            <span>  <ReactCountryFlag countryCode={this.getSelectedLanguage().flag}
                                                      svg/> {this.getSelectedLanguage().label}</span>
                        </Button>
                    </div>


                    <div className="cover">
                        <img width="100%" height="100%" alt="QuickPay"
                             src={this.state.parking.parking_express_image}/>
                    </div>
                    <div className="ticket-information">
                        <TicketInformation ticketInformation={this.state.ticketInformation}/>

                    </div>


                    <Row className="container-pay-message justify-content-center">

                        <div>
                            <img className="parking-image" alt="QuickPay"
                                 src={document.querySelector('meta[name="bmoov_welcome_logo"]') ? document.querySelector('meta[name="bmoov_welcome_logo"]').content : ''}/>

                        </div>

                    </Row>
                </div>


                <div className="container-options">

                    {this.state.error && (

                        <Row className="justify-content-center  ">
                            <Alert sm={12} key="danger" variant="danger">
                                {this.state.errorMessage}

                            </Alert>
                        </Row>

                    )}
                    <PaymentOptions reference={this.state.reference} bag={this.state.ticketInformation.data}
                                    paymentSpinner={this.state.paymentSpinner}/>

                </div>

                <Modal className="py-1 d-flex justify-content-center modal spinner-modal" show={this.state.showSpinner}>
                    <div className="spinner">

                        <img width="70%" height="70%" src={require('../assets/images/spinner.svg').default}
                             alt='mySvgImage'/>
                        <p className="spinner-message">{trans('please_wait', 'Please wait...')}</p>
                    </div>
                </Modal>

                <Modal className="py-1 d-flex justify-content-center modal" show={this.state.showModal}>


                    {!this.state.showSpinner && (
                        <div>
                            <Modal.Header closeButton>
                                <Modal.Title
                                    className="modal-title">{trans('license_plate_input_title', 'Enter the registration number')} </Modal.Title>
                            </Modal.Header>

                            <form>
                                <Modal.Body>

                                    {this.state.lpnError && (
                                        <Alert key="danger" variant="danger">
                                            {this.state.lpnErrorMessage}
                                        </Alert>

                                    )
                                    }

                                    <input type="text" className="form-control " id="lpn"></input>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="primary" className="btn-primary "
                                            onClick={e => this.retrieveLpnData(e)}>Next</Button>
                                </Modal.Footer>
                            </form>
                        </div>
                    )
                    }
                </Modal>

            </div>


        )
    }
}










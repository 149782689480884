import React, {useState, useEffect} from 'react'
import {Button, Col, Form, FormControl, InputGroup, Row} from 'react-bootstrap'
import API from '../../Components/Backend/API'
import trans from '../../helpers'
import * as Yup from 'yup'
import {Formik} from 'formik'
import Select from 'react-select'
import {components} from "react-select";
import {AiFillCheckCircle} from "react-icons/ai";
import {AiFillExclamationCircle} from "react-icons/ai";
import {CountdownCircleTimer} from 'react-countdown-circle-timer'

import './SuccessPage.scss'

const SuccessPage = props => {


    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('');
    const [showDetails, setShowDetails] = useState(false)
    const [showSendButton, setShowSendButton] = useState(false)
    const [error, setError] = useState(false);
    const [showSmsCounter, setShowSmsCounter] = useState(false);


    const [wsErrors, setWsErrors] = useState({
        phone: '',
        country: ''
    })
    const [details, setDetails] = useState({
        parking_address: '',
        entry_datetime: '',
        payment_datetime: '',
        payment_duration: '',
        payment_provider: '',
        reference: '',
        receipt_number: '',
        amount: ''
    })

    useEffect(() => {

        if (localStorage.getItem('reference') !== '' && localStorage.getItem('reference') !== null) {

            API.post(`payment/details`, {reference: localStorage.getItem('reference'), phone: ''})
                .then(res => {
                    setDetails({...res.data.payment_details})
                    setShowDetails(true)
                    setShowSendButton(res.data.show_send_form)
                }).catch(error => {
                setError(true)

                // alert(JSON.stringify(error, null, 2))
            })
        }

    }, [])// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        API.get(`sms/countries/codes`)
            .then(res => {
                setCountries([...res.data.countries])
                setCountry(res.data.countries[res.data.default])
            }).catch(error => {
            // console.log(error)
            // alert(JSON.stringify(error, null, 2))
        })
    }, [])
    const {Option} = components;
    const IconOption = (props) => (
        <Option {...props}>
            {props.data.flag && (
                <img
                    src={props.data.flag}
                    style={{width: 40}}
                    alt={props.data.label}
                />
            )}
            &nbsp;
            {props.data.label}
        </Option>
    );

    let schema = Yup.object({
        phone: Yup.number()
            .typeError(trans('phone_verification_number', 'Phone must be a number'))
            .required(trans('phone_verification_required', 'Phone is required')),
        // country: Yup.string()
        // .required(trans('country_code_verification_required', 'Please select a country code')),
    })

    const reloadPage = data => {
        window.location.reload();
    }

    const sendMeTheReceipt = data => {


        const payload = {reference: localStorage.getItem('reference'), phone: country.value + data.phone}
        setShowSmsCounter(true)
        document.getElementById("send-sms-btn").disabled = true


        API.post(`payment/receipt`, payload)
            .then(res => {
                document.getElementById("send-sms-btn").classList.remove("spinner-loading")
                if (!res.data.success) {
                    setWsErrors({
                        server_error: res.data.message
                    })

                } else {

                    if (res.data.hide_button) {
                        setShowSendButton(false)
                    }

                    // console.log(res.data.message)
                    // values.showSecondForm({ message: res.data.message, phone: payload.phone })
                }
            }).catch(error => {
             document.getElementById("send-sms-btn").classList.remove("spinner-loading")
             setWsErrors({
                server_error: 'System error.'
            })
            // console.log(error)
            // alert(JSON.stringify(error, null, 2))
        })
        // console.log('send data: ', payload)

    }

    return (
        <>
            {error === true && (
                <div className='success-page mt-4'>
                    <Row className='first-section'>
                        <Col lg={12} md={12} sm={12}>
                            <AiFillExclamationCircle size={70} color="#B33A3A"/>
                        </Col>
                    </Row>
                    <br/>
                    <>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <p>{trans('payment_error', 'It looks like we have problem with the selected payment method, please chose another one.')}</p>
                            </Col>
                            <Col lg={12} md={12} sm={12}>
                                <Button variant="primary" className="payment-try-again-btn"
                                        onClick={reloadPage}
                                        id="spinner">{trans('payment_try_again', 'Try Again')}</Button>

                            </Col>
                        </Row>

                    </>
                </div>
            )}
            {error === false && (
                <div className='success-page mt-4'>

                    <Row className='first-section'>
                        <Col lg={12} md={12} sm={12}>
                            <AiFillCheckCircle size={70} color="#27ae60"/>
                        </Col>
                    </Row>

                    <br/>
                    {showDetails && (
                        <>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <p>{trans('payment_success_message', 'THANK YOU FOR YOUR PAYMENT')}</p></Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <p>{trans('payment_details_reference', 'Payment reference ID')}: {details.reference}</p>
                                </Col>
                            </Row>


                            {showSendButton && (
                                <Formik
                                    validationSchema={schema}
                                    onSubmit={sendMeTheReceipt}
                                    initialValues={{}}
                                >
                                    {({
                                          handleSubmit,
                                          handleChange,
                                          touched,
                                          errors,
                                      }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Row className='col-input-succes form-submit'>
                                                <Col lg={12} md={12} sm={12}>
                                                    <InputGroup className="mb-3 ">

                                                        <Select
                                                            options={countries}
                                                            components={{Option: IconOption}}
                                                            className="list country"
                                                            name='country'
                                                            menuPosition={'fixed'}
                                                            menuPlacement="top"
                                                            value={country}
                                                            onChange={(name, value) => {
                                                                handleChange('value.name')(name.value);
                                                                setCountry(name);
                                                            }}
                                                            error={errors.country}
                                                            touched={touched.country}
                                                            placeholder={trans('select_country_placeholder', 'Select a country')}
                                                        />


                                                        <FormControl
                                                            placeholder={trans('phone_placeholder_2', '12345678')}
                                                            inputMode="tel"
                                                            className='phone text-left'
                                                            name='phone'
                                                            autoComplete='off'
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.phone}
                                                            isValid={touched.phone && !errors.phone}
                                                        />


                                                        <div className="input-group-append">
                                                            <Button variant="primary" type='submit'
                                                                    id="send-sms-btn"
                                                            >
                                                                <div className="send-sms-container">

                                                                    <span className="sms-counter">

     {showSmsCounter && (
                                                                    <CountdownCircleTimer
                                                                                      size={30}
                                                                                          strokeWidth={3}
                                                                                          trailStrokeWidth={3}
                                                                                          isPlaying
                                                                                          duration={30}
                                                                                      colors={['#6c757d','#6c757d','#6c757d','#6c757d']}
                                                                                      onComplete={() => {
                                                                                          document.getElementById("send-sms-btn").disabled = false
                                                                                          setShowSmsCounter(false)

                                                                                      }}

                                                                    >
                                                                        {({remainingTime}) => remainingTime}
                                                                    </CountdownCircleTimer>

     )}


                                                                  </span>
                                                                    <span>
                                                                        {trans('send_sms_button_2', 'Send me a copy')}
                                                                  </span>
                                                                </div>





                                                            </Button>

                                                        </div>


                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.phone}{wsErrors.phone}
                                                        </Form.Control.Feedback>
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.country}{wsErrors.country}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default SuccessPage

import axios from 'axios'

// window.location.hostname will copy the host and remove the port ;)
const instance = axios.create(process.env.NODE_ENV === "development" ? { baseURL: 'http://'+window.location.hostname+'/'} : {})

instance.interceptors.request.use(request => {

    request.headers = { ...request.request, 'Accept-Language': localStorage.getItem('language') }

    if (process.env.NODE_ENV === "development") {
        console.log('Sent request', request);
    }

    return request;
});

instance.interceptors.response.use(response => {
    if (process.env.NODE_ENV === "development") {
        console.log('Received response:', response);
    }

    return response;
});

export default instance;

import React, {useState, useEffect} from 'react'
import API from "../Components/Backend/API.js";
import InnerHTML from 'dangerously-set-html-content'

import {Alert, Form, Image, Modal, Row} from 'react-bootstrap'
import {Col} from 'react-bootstrap'
import trans from '../helpers'
import './PaymentOptions.css'
import SuccessPage from "../Components/SuccessPage/SuccessPage";


const PaymentOptions = props => {

    const [options, setOptions] = useState([{
        name: '',
        logo: '',
        key: ''
    }])
    const [payment, setPayment] = useState('')
    const [error, setError] = useState(false)
    const [selectedPayment, setSelectedPayment] = useState(options[0].key)
    const [showModal, setShowModal] = useState(false);
    const [reference, setReference] = useState('')
    const [provider, setProvider] = useState('')

    const handlePayClick = data => {

        setShowModal(true);

        if (!data.target.value) return null;

        let provider = data.target.value;
        setProvider(provider)

        setError(false)
        // document.querySelector("#spinner-3").classList.add("spinner-loading")
        // document.querySelector("#spinner-3").disabled = true
        API.post(`payment`, {
            provider: (provider ? provider : 'test'),
            ticket_type: props.bag.ticket_type,
            ticket_value: props.bag.ticket_value,
            qr: localStorage.getItem('parking'),
            client: localStorage.getItem('client')
        })
            .then(res => {


                if (res.data.success) {
                    // When the payment is working update the step to 5 from the callback
                    // so comment the above line and uncomment the second line
                    // props.updateStep({ step: 5 })
                    //
                    if (provider === 'test-clicktopay' || provider === 'clicktopay' ){
                        window.location.href = res.data.content;
                    }

                    localStorage.setItem("ticket_type", props.bag.ticket_type);
                    localStorage.setItem("ticket_value", props.bag.ticket_value);
                    setPayment(res.data.content)
                    setShowModal(true);


                 } else {
                    // document.querySelector("#spinner-3").classList.remove("spinner-loading")
                    // document.querySelector("#spinner-3").disabled = false
                    setError(res.data.message)
                    setShowModal(false);

                }


            }).catch(error => {
            setShowModal(false);

            setError(trans('service_unavailable', 'System unavailable, please try again later.'))
            // document.querySelector("#spinner-3").classList.remove("spinner-loading")
            // document.querySelector("#spinner-3").disabled = false
        })
    }

    const changePaymentOption = data => {
        setError(false)
        setSelectedPayment(data.target.value)
    }


    useEffect(() => {


      setReference(props.reference)

        setShowModal(props.paymentSpinner)
    }, [props.paymentSpinner, props.reference])


    useEffect(() => {
        let element = document.getElementById('payment-options');
        element.classList.add('is-loading')
        API.get(`payment/options?parking_qr=${localStorage.getItem('parking')}`)
            .then(res => {
                setOptions(res.data.options)
                setSelectedPayment(res.data.options[0].key)
                element.classList.remove('is-loading')
            }).catch(error => {
            element.classList.remove('is-loading')
        })

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>

            <Modal id="spinner-modal-xx"
                   dialogClassName='custom-dialog-xx'
                   className="py-1 d-flex justify-content-center spinner-modal "
                   show={showModal}>

                <div className="spinner">
                    <img width="75%" height="75%" src={require('../assets/images/spinner.svg').default}
                         alt='mySvgImage'/>
                    <p className="spinner-message">{trans('please_wait', 'processing payment…')}</p>
                </div>
            </Modal>


            <Modal className="py-1 d-flex justify-content-center modal success-modal"
                   show={reference !== '' && reference !== null && reference !== undefined}>
                <SuccessPage/>
            </Modal>


            <div className='payment-options' id='payment-options'>
                <div>
                    { payment && (
                    <Row id="xxxxxxxx" style={{display: provider  === 'applepaycredimax' ? 'block' : 'none'}}>

                        <InnerHTML className="col-md-12" html={payment}/>
                    </Row>
                    )}
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}><p
                            className='title'>{trans('select_payment_title', 'Payment options')}</p>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            {!!error && (

                                <Alert sm={12} key="danger" variant="danger">
                                    {error}
                                </Alert>

                            )}
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className='payment-option dark-scheme'>
                            {options.map((option, index) => {
                                return (

                                    <Row onClick={handlePayClick} key={index}
                                         className="option-container justify-content-center dark-scheme">
                                        <Col className="justify-content-center" lg={12} md={12} sm={12} xs={12}>
                                            <Form.Check
                                                value={option.key ? option.key : 'test'}
                                                label={
                                                    <Row className="justify-content-center">
                                                        <Col lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                            <p>{option.name}</p>
                                                        </Col>
                                                        <Col lg={'auto'} md={'auto'} sm={'auto'} xs={'auto'}>
                                                            <Image src={option.logo}/>
                                                        </Col>
                                                    </Row>


                                                }
                                                custom
                                                name='payment-option'
                                                type='radio'
                                                onChange={changePaymentOption}
                                                checked={option.key === selectedPayment}
                                                id={'payment' + index + 1}
                                            />
                                        </Col>
                                    </Row>
                                )
                            })}
                        </Col>

                        <Col lg={12} md={12} sm={12} xs={12}>
                        </Col>
                    </Row>
                </div>
            </div>


        </>
    )
}

export default PaymentOptions
